export const environment = {
  production: true,
  // Web config for Firebase
  firebaseConfig: {
    apiKey: 'AIzaSyB7QzajmmSUqR1FPE0WnnVHNxbvGBTzGDo',
    authDomain: 'ishavskraft-mobile-tracking.firebaseapp.com',
    projectId: 'ishavskraft-mobile-tracking',
    storageBucket: 'ishavskraft-mobile-tracking.appspot.com',
    messagingSenderId: '355048236870',
    appId: '1:355048236870:web:e1076f744c2ed79f102747',
    measurementId: 'G-54DQJ4EE63'
  }
};
