import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { analyseRoute, forsideRoute, nyttigRoute } from 'src/app/routes';

export const analyseRouterStateGuard: CanActivateFn = (route, state) => {
  const navState = inject(Router).getCurrentNavigation().extras?.state;
  if (navState) {
    return true;
  } else {
    inject(Router).navigate([analyseRoute]);
    return false;
  }
};

export const nyttigRouterStateGuard: CanActivateFn = (route, state) => {
  const navState = inject(Router).getCurrentNavigation().extras?.state;
  if (navState) {
    return true;
  } else {
    inject(Router).navigate([nyttigRoute]);
    return false;
  }
};

export const loginRouterStateGuard: CanActivateFn = (route, state) => {
  const navState = inject(Router).getCurrentNavigation().extras?.state;
  if (navState) {
    return true;
  } else {
    inject(Router).navigate([forsideRoute]);
    return false;
  }
};
