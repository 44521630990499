import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { App, AppInfo } from '@capacitor/app';
import * as LiveUpdates from '@capacitor/live-updates';
import { AlertController, Platform } from '@ionic/angular';
import { NativeMarket } from '@capacitor-community/native-market';
import { FcmTokenService } from './services/fcm-token/fcm-token.service';
import { MaintenanceMessage } from './services/fcm-token/fcm-token.types';
import { Preferences } from '@capacitor/preferences';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    public router: Router,
    private fcmTokenService: FcmTokenService,
    private platform: Platform,
    private alertController: AlertController
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    // Sjekk vedlikeholdsmeldinger
    this.fcmTokenService.getActiveMaintenanceMessages().subscribe((res) => {
      const msg = res?.length > 0 ? res[0] : undefined;
      if (
        msg?.active &&
        new Date(msg.fromDate) <= new Date() &&
        new Date(msg.toDate) > new Date()
      ) {
        this.showMaintenanceAlert(msg);
      }
    });

    // Sjekk om nyeste versjon
    if (this.platform.is('capacitor')) {
      // Sjekk om vi må oppdatere på init
      this.checkAppVersion();

      // First sync on app load
      LiveUpdates.sync().then((res) => {
        Preferences.set({
          key: 'shouldReloadApp',
          value: res.activeApplicationPathChanged.toString()
        });
      });

      // Hvis appen lukkes og åpnes igjen, sjekk om vi må oppdatere
      this.platform.resume.subscribe(async () => {
        this.checkAppVersion();

        // Live Update check
        Preferences.get({
          key: 'shouldReloadApp'
        }).then(async ({ value }) => {
          if (value === 'true') {
            await LiveUpdates.reload();
          } else {
            LiveUpdates.sync().then((res) => {
              Preferences.set({
                key: 'shouldReloadApp',
                value: res.activeApplicationPathChanged.toString()
              });
            });
          }
        });
      });
    }

    // Gamle nettlesere har ikke ResizeObserver som Chart.js bruker,
    // så her må vi erstatte med en annen resize observer for at grafene skal fungere
    (async () => {
      if ('ResizeObserver' in window === false) {
        // Loads polyfill asynchronously, only if required.
        const module = await import('@juggle/resize-observer');
        //@ts-ignore-next-line
        window.ResizeObserver = module.ResizeObserver;
      }
    })();

    // Deep linking - kun mobilapp
    App.addListener('appUrlOpen', (data: any) => {
      if (data.url?.startsWith('https://minside.ishavskraft.no')) {
        const slug = data.url.split('.no').pop();
        this.router.navigate([slug]);
      }
    });
  }

  async checkAppVersion() {
    this.fcmTokenService.getLatestReleases().subscribe((res) => {
      App.getInfo().then((info: AppInfo) => {
        const currentVersion = info.version;
        const latestVersion = this.platform.is('ios')
          ? res.find((x) => x.platform === 'ios')
          : this.platform.is('android')
          ? res.find((x) => x.platform === 'android')
          : undefined;
        if (latestVersion && currentVersion) {
          this.checkAppVersionUpdate(
            currentVersion,
            latestVersion.version,
            latestVersion.forced,
            latestVersion.lastForced
          );
        }
      });
    });
  }

  async checkAppVersionUpdate(
    current: string,
    latest: string,
    forced: boolean,
    latestForced?: string
  ) {
    if (
      forced ||
      (latestForced && this.hasOlderVersion(current, latestForced))
    ) {
      const alert = await this.alertController.create({
        cssClass: 'push-alert push-alert-blocks',
        mode: 'ios',
        header: 'Oppdater appen',
        message:
          'Det finnes en ny versjon av appen med viktige endringer. Du må oppdatere appen for å fortsette å bruke den.',
        buttons: [
          {
            text: 'Oppdater',
            role: 'confirm',
            handler: () => {
              NativeMarket.openStoreListing({
                appId: this.platform.is('ios')
                  ? 'id1528706031'
                  : 'no.Ishavskraft.Ishavskraft'
              });
            }
          }
        ],
        backdropDismiss: false
      });
      await alert.present();
    } else if (this.hasOlderVersion(current, latest)) {
      const alert = await this.alertController.create({
        cssClass: 'push-alert',
        mode: 'ios',
        header: 'Oppdater appen',
        message: 'Det finnes en ny versjon av appen.',
        buttons: [
          {
            text: 'Avbryt',
            role: 'cancel'
          },
          {
            text: 'Oppdater',
            role: 'confirm',
            handler: () => {
              NativeMarket.openStoreListing({
                appId: this.platform.is('ios')
                  ? 'id1528706031'
                  : 'no.Ishavskraft.Ishavskraft'
              });
            }
          }
        ]
      });
      await alert.present();
    }
  }

  hasOlderVersion(current: string, latest: string) {
    const currentSplitted = current.split('.').map((x) => Number(x));
    const compareSplitted = latest.split('.').map((x) => Number(x));
    return (
      compareSplitted[0] > currentSplitted[0] ||
      (compareSplitted[0] === currentSplitted[0] &&
        compareSplitted[1] > currentSplitted[1]) ||
      (compareSplitted[0] === currentSplitted[0] &&
        compareSplitted[1] === currentSplitted[1] &&
        compareSplitted[2] > currentSplitted[2])
    );
  }

  async showMaintenanceAlert(message: MaintenanceMessage) {
    const alert = await this.alertController.create({
      cssClass: `push-alert ${message.blockApp ? 'push-alert-blocks' : ''}`,
      mode: 'ios',
      header: message.title,
      message: message.message,
      buttons: message.blockApp
        ? []
        : [
            {
              text: 'Lukk',
              role: 'confirm'
            }
          ],
      backdropDismiss: !message.blockApp
    });
    await alert.present();
  }
}
