import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  PreloadAllModules,
  RouteReuseStrategy,
  Router,
  RouterModule
} from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializer } from './app-init';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainPipe } from './services/pipes/main-pipe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpInterceptorService } from './services/keycloak/http-interceptor.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ComponentsModule } from './shared/components.module';
import { AppRoutes } from './app.routes';
import { AnalyticsService } from './services/analytics/analytics.service';
import { BrukerService } from './services/bruker/bruker.service';
import { PushNotificationsService } from './services/push-notifications/push-notifications.service';
import { AvtaleService } from './services/avtale/avtale.service';
import { provideMatomo } from 'ngx-matomo-client';
import { withRouter } from 'ngx-matomo-client';
import p from 'package.json';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md', innerHTMLTemplatesEnabled: true }),
    RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules }),
    KeycloakAngularModule,
    MainPipe,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    ComponentsModule
  ],
  providers: [
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [
        KeycloakService,
        AnalyticsService,
        BrukerService,
        PushNotificationsService,
        AvtaleService,
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    provideMatomo(
      { trackerUrl: 'https://ishavskraft.matomo.cloud/', siteId: '2' },
      withRouter()
    ),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
