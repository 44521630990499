import type { AirDatepickerLocale } from 'air-datepicker';

export function addHours(date: Date, hours: number) {
  const result = new Date(date);
  result.setHours(result.getHours() + hours);
  return result;
}
export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export function addMonth(date: Date, months: number) {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);

  if (date.getMonth() == result.getMonth()) {
    result.setMonth(result.getMonth() + months);
  }

  return result;
}
export function addYear(date: Date, years: number) {
  const result = new Date(date);
  result.setFullYear(result.getFullYear() + years);
  return result;
}

export const months = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember'
];

export const monthsShort = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAI',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OKT',
  'NOV',
  'DES'
];

export const getMonthNameByIndex = (month: Date) => months[month.getMonth()];

export function formatDateToString(
  dateString: string,
  year: 'full' | 'short' | 'none' = 'full'
) {
  const date = new Date(dateString);
  const yearString =
    year === 'none'
      ? ''
      : year === 'short'
      ? '.' + date.getFullYear().toString().slice(-2)
      : '.' + date.getFullYear();
  return (
    date.getDate().toString().padStart(2, '0') +
    '.' +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    yearString
  );
}

export function formatDateToISOString(date: Date) {
  return `${date.getFullYear().toString()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

export const CustomLocaleNb: AirDatepickerLocale = {
  days: [
    'Søndag',
    'Mandag',
    'Tirsdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lørdag'
  ],
  daysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
  daysMin: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
  months: [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember'
  ],
  monthsShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Des'
  ],
  today: 'I dag',
  clear: 'Tøm',
  dateFormat: 'dd.MM.yyyy',
  timeFormat: 'hh:ii',
  firstDay: 1
};

export function formatYYYYMMDDNumberToDate(value: number) {
  const str = value.toString();
  return new Date(
    Number(str.substring(0, 4)),
    Number(str.substring(4, 6)) - 1,
    Number(str.substring(6, 8))
  );
}

export function formatYYYYMMNumberToDate(value: number) {
  const str = value.toString();
  return new Date(Number(str.substring(0, 4)), Number(str.substring(4, 6)) - 1);
}

export function formatYYYYMMDDHHNumberToDate(value: number) {
  const str = value.toString();
  return new Date(
    Number(str.substring(0, 4)),
    Number(str.substring(4, 6)) - 1,
    Number(str.substring(6, 8)),
    Number(str.substring(8, 10)),
    0,
    0
  );
}
