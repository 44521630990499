import { SplashScreen } from '@capacitor/splash-screen';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { AnalyticsService } from './services/analytics/analytics.service';
import { BrukerService } from './services/bruker/bruker.service';
import { KeycloakUserInfo } from './services/bruker/bruker.types';
import { PushNotificationsService } from './services/push-notifications/push-notifications.service';
import { Capacitor } from '@capacitor/core';
import { from } from 'rxjs';
import { AvtaleService } from './services/avtale/avtale.service';
import { MatomoTracker } from 'ngx-matomo-client';

export function initializer(
  keycloak: KeycloakService,
  analytics: AnalyticsService,
  brukerService: BrukerService,
  pushNotifications: PushNotificationsService,
  avtaleService: AvtaleService,
  tracker: MatomoTracker
): () => Promise<any> {
  // Event listener på keycloak events
  from(keycloak.keycloakEvents$).subscribe({
    next: (e) => {
      if (e.type == KeycloakEventType.OnTokenExpired) {
        this.keycloakService.updateToken(20);
      }
      if (e.type == KeycloakEventType.OnAuthLogout) {
        this.pushNotifications.unsubscribeFromAllTopics();
      }
      if (e.type == KeycloakEventType.OnAuthSuccess) {
        keycloak
          .getKeycloakInstance()
          .loadUserInfo()
          .then(async (x: KeycloakUserInfo) => {
            const isEier = keycloak.isUserInRole('eier');
            if (isEier) {
              // Ikke track admins
              analytics.setCollectionEnabled(false);
            } else {
              brukerService.getSamtykke().subscribe({
                next: (samtykkeRes) => {
                  if (samtykkeRes.samtykke.tillaterSporing === false) {
                    analytics.setCollectionEnabled(false);
                  } else {
                    analytics.setCollectionEnabled(true);
                  }
                },
                error: (error) => {
                  analytics.setCollectionEnabled(false);
                }
              });
            }

            if (x?.kunde_id) {
              analytics.setUser(x.kunde_id);

              // Push notifications er ikke implementert på web
              const isPushNotificationsAvailable =
                Capacitor.isPluginAvailable('PushNotifications');
              if (isPushNotificationsAvailable) {
                avtaleService.getAvtaler().subscribe({
                  next: async (avtaleRes) => {
                    if (avtaleRes.avtaler) {
                      const uniqueOmrader = avtaleRes.avtaler
                        .map((a) => a.prisomrade)
                        .filter(
                          (value, index, listOfOmrader) =>
                            listOfOmrader.indexOf(value) === index
                        );
                      await pushNotifications.initializePushNotifications(
                        x.kunde_id,
                        uniqueOmrader
                      );
                      await pushNotifications.showNotificationInfoAlert();
                    }
                  }
                });
              }
            }
          });
      }
    }
  });

  return (): Promise<any> =>
    keycloak
      .init({
        config: {
          url: 'https://bruker.ishavskraft.no/auth',
          //url: 'http://localhost:8080/auth',
          realm: 'Privat',
          clientId: 'app'
        },
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false
          //silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets'],
        loadUserProfileAtStartUp: true
      })
      .then(function () {
        SplashScreen.hide();
      })
      .catch((error) => console.log('error with keycloak ', error));
}
