import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { AvtaleUpdate, Avtaler } from '../avtale/avtale.types';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  avtale: Avtaler;
  newInvType: string;
  strAvtaletype: AvtaleUpdate;
  strUpdateType: string;
  avtaler: Avtaler[];

  byttetKunde: boolean;

  showFaktura: boolean;
  strFakturaNr: string;
  showGjennomfakturering: boolean;

  kundechangesubject = new ReplaySubject<string>(1);
  kundeObservable$ = this.kundechangesubject.asObservable();

  constructor() {}

  setByttetKunde(kunde_id: string) {
    this.kundechangesubject.next(kunde_id);
  }
  getKundebytte(): Observable<string> {
    return this.kundechangesubject.asObservable();
  }

  setShowFaktura(
    blnValue: boolean,
    strFakturaNr: string,
    blnGjennomfakturering: boolean
  ) {
    this.showFaktura = blnValue;
    this.strFakturaNr = strFakturaNr;
    this.showGjennomfakturering = blnGjennomfakturering;
  }
  clearShowFaktura() {
    this.showFaktura = false;
    this.strFakturaNr = '';
    this.showGjennomfakturering = false;
  }
  getShowFaktura(): boolean {
    return this.showFaktura;
  }
  getGjennomfakturering(): boolean {
    return this.showGjennomfakturering;
  }
  getFakturaNr(): string {
    return this.strFakturaNr;
  }

  clearByttetKunde() {
    this.byttetKunde = false;
  }
  getByttetKunde(): boolean {
    return this.byttetKunde;
  }

  setAvtaleData(avtale: Avtaler) {
    this.avtale = avtale;
  }

  getAvtaleData(): Avtaler {
    return this.avtale;
  }

  getAvtaler(): Avtaler[] {
    return this.avtaler;
  }

  setAvtaler(avtaler: Avtaler[]) {
    this.avtaler = avtaler;
  }

  setNewInvType(newDealType: string) {
    this.newInvType = newDealType;
  }
  getNewInvType(): string {
    return this.newInvType;
  }

  setAvtaleType(strAvtaletype: AvtaleUpdate) {
    this.strAvtaletype = strAvtaletype;
  }

  getAvtaleType(): AvtaleUpdate {
    return this.strAvtaletype;
  }

  setUpdateType(strUpdateType: string) {
    this.strUpdateType = strUpdateType;
  }

  getUpdateType(): string {
    return this.strUpdateType;
  }
}
