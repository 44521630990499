import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  FcmToken,
  FcmTokenWithPushPreferenceResponse,
  LatestRelease,
  MaintenanceMessage
} from './fcm-token.types';

@Injectable({
  providedIn: 'root'
})
export class FcmTokenService {
  rootUrl = 'https://appgw.prod.iktest.co/api-fcmtoken';

  constructor(private _httpClient: HttpClient) {}

  getFcmTokenWithPreferences(
    deviceName: string,
    kundeId: string
  ): Observable<FcmTokenWithPushPreferenceResponse> {
    return this._httpClient.get<FcmTokenWithPushPreferenceResponse>(
      `${
        this.rootUrl
      }/fcmtoken/with-preferences?deviceName=${encodeURIComponent(
        deviceName
      )}&kundeId=${encodeURIComponent(kundeId)}`
    );
  }

  getFcmToken(deviceName: string, kundeId: string): Observable<FcmToken> {
    return this._httpClient.get<FcmToken>(
      `${this.rootUrl}/fcmtoken?deviceName=${encodeURIComponent(
        deviceName
      )}&kundeId=${encodeURIComponent(kundeId)}`
    );
  }

  postFcmToken(payload: string) {
    return this._httpClient.post(`${this.rootUrl}/fcmtoken`, payload);
  }

  postPushPreferences(payload: string) {
    return this._httpClient.post(`${this.rootUrl}/pushpreference`, payload);
  }

  getPushPreferences(
    deviceName: string,
    kundeId: string
  ): Observable<FcmTokenWithPushPreferenceResponse> {
    return this._httpClient.get<FcmTokenWithPushPreferenceResponse>(
      `${this.rootUrl}/pushpreference?deviceName=${encodeURIComponent(
        deviceName
      )}&kundeId=${encodeURIComponent(kundeId)}`
    );
  }

  getLatestReleases(): Observable<LatestRelease[]> {
    return this._httpClient.get<LatestRelease[]>(
      `${this.rootUrl}/latestrelease`
    );
  }

  getActiveMaintenanceMessages(): Observable<MaintenanceMessage[]> {
    return this._httpClient.get<MaintenanceMessage[]>(
      `${this.rootUrl}/maintenancemessage/active`
    );
  }
}
