import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    return from(this.keycloakService.getToken()).pipe(
      switchMap((token) => {
        const headers = req.headers
          .set('Authorization', 'Bearer ' + token)
          .append('Content-Type', 'application/json');
        const requestClone = req.clone({
          headers
        });
        return next.handle(requestClone);
      })
    );
  }
}
