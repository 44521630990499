import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { forsideRoute } from 'src/app/routes';

export const logoutGuard: CanActivateFn = (route, state) => {
  const isLoggedIn = inject(KeycloakService).isLoggedIn();
  if (isLoggedIn) {
    return true;
  } else {
    inject(Router).navigate([forsideRoute]);
    return false;
  }
};
