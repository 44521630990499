import { Injectable, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { sha256 } from 'js-sha256';

// Init for the web
import '@capacitor-community/firebase-analytics';

import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { MatomoTracker } from 'ngx-matomo-client';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  isWeb: boolean;
  sendAnalytics = !isDevMode();

  constructor(
    private router: Router,
    private platform: Platform,
    private tracker: MatomoTracker
  ) {
    this.isWeb = !this.platform.is('capacitor');
    this.initFb();

    // Log screen views using URL
    this.router.events
      .pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe((e: RouterEvent) => {
        // Log screen views for mobile
        this.setScreenName(e.url.toString(), e.url.toString());

        // Log screen views for web
        if (this.isWeb && this.sendAnalytics) {
          this.logEvent('screen_view', {
            screen_name: e.url,
            nameOverride: e.url
          });
        }
      });
  }

  setCollectionEnabled(enabled: boolean) {
    if (enabled) {
      this.tracker.forgetUserOptOut();
    } else {
      this.tracker.optUserOut();
    }
    if (this.sendAnalytics) {
      FirebaseAnalytics.setCollectionEnabled({
        enabled: enabled
      });
    }
  }

  // Only required for web
  async initFb() {
    if (this.isWeb && this.sendAnalytics) {
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }

  /**
   * Identify logs by a specific user.
   * @param userId The id to be hashed using SHA256
   */
  setUser(userId: string) {
    const hashedUserId = sha256(userId);
    if (this.sendAnalytics) {
      this.tracker.setUserId(hashedUserId);
      // Use SHA256 of kundeid
      FirebaseAnalytics.setUserId({
        userId: hashedUserId
      });
    }
  }

  /**
   * Add a property to the user logs. You can have up to 25 unique properties, so don’t store every trash in them.
   * @param name Name of property
   * @param value Value of property
   */
  setProperty(name: string, value: string) {
    if (this.sendAnalytics) {
      FirebaseAnalytics.setUserProperty({
        name: name,
        value: value
      });
    }
  }

  logEvent(name: string, params?: any) {
    if (this.sendAnalytics) {
      FirebaseAnalytics.logEvent({
        name,
        params
      });
    }
  }

  logEvent_errorFetch(location: string, errorMsg: string) {
    this.tracker.trackEvent(
      'Error',
      'Fetch ' + location,
      errorMsg,
      Number(new Date().getHours() + '' + new Date().getMinutes())
    );
    this.logEvent('error_fetch', {
      url: location,
      message: errorMsg
    });
  }

  logEvent_selectContent(action: string, itemId?: string) {
    this.tracker.trackEvent('Button', 'Click', action);
    this.logEvent('select_content', {
      // Navn på action - "Parameter value" i dashboardet i FB
      content_type: action,
      item_id: itemId
    });
  }

  logEvent_viewItem(itemName: string, itemCategory?: string) {
    this.tracker.trackEvent('Button', 'Click', itemName + ' ' + itemCategory);
    this.logEvent('view_item', {
      items: [
        {
          item_name: itemName,
          item_category: itemCategory
        }
      ]
    });
  }

  /**
   * Only works on ios/Android! Track the view of a specific page/screen of your app.
   * I added a little logic to connect this with the router events so you don’t need to manually call this in each of your pages, but the information from the router is limited to the URL itself.
   * @param screenName
   * @param screenClass Override
   */
  setScreenName(screenName: string, screenClass: string) {
    if (this.sendAnalytics) {
      FirebaseAnalytics.setScreenName({
        screenName: screenName,
        nameOverride: screenClass
      });
    }
  }
}
