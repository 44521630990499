// Root for tabs
export const rootTabs = 'tabs';

// Tabs
export const profilTab = 'profil';
export const regningTab = 'regninger';
export const forbrukTab = 'forbruk';
export const avtaleTab = 'avtaler';
export const nyttigTab = 'nyttig';
export const spotprisTab = 'spot';
export const brokerPage = 'broker';
export const articlePage = 'artikkel';
export const enodeTab = 'enode';
export const analyseTab = 'analyse';
export const nettleiePage = 'nettleie';
export const smartScorePage = 'forbruk';
export const loginRoute = 'logginn';
export const forsideRoute = `/${loginRoute}`;
export const loginArticleRoute = `${loginRoute}/${articlePage}`;

// Tab routes
export const profilRoute = `/${rootTabs}/${profilTab}`;
export const regningRoute = `/${rootTabs}/${regningTab}`;
export const forbrukRoute = `/${rootTabs}/${forbrukTab}`;
export const avtaleRoute = `/${rootTabs}/${avtaleTab}`;
export const nyttigRoute = `/${rootTabs}/${nyttigTab}`;
export const spotprisRoute = `/${rootTabs}/${spotprisTab}`;
export const brokerRoute = `/${brokerPage}`;
export const enodeRoute = `/${rootTabs}/${enodeTab}`;
export const analyseRoute = `/${rootTabs}/${analyseTab}`;
export const analyseNettleieRoute = `/${rootTabs}/${analyseTab}/${nettleiePage}`;
export const smartScoreRoute = `/${rootTabs}/${analyseTab}/${smartScorePage}`;
