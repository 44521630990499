import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { Observable, catchError, of } from 'rxjs';
import {
  BrukerResponse,
  BrukerkundeResponse,
  KundeResponse,
  PasswordResponse,
  PostBrukerkundeResponse,
  SamtykkeResponse
} from 'src/app/services/bruker/bruker.types';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class BrukerService {
  constructor(
    private _httpClient: HttpClient,
    private _keycloakService: KeycloakService,
    private analytics: AnalyticsService
  ) {}

  getUserInfo(): Promise<Keycloak.KeycloakProfile> {
    return this._keycloakService.loadUserProfile(true);
  }

  sendSms(mobilnummer: string): Observable<BrukerResponse> {
    return this._httpClient.post<BrukerResponse>(
      'https://apiprivat.ishavskraft.no/bruker/koble/mobilnr/' + mobilnummer,
      null
    );
  }

  sendKode(kode: string): Observable<BrukerResponse> {
    if ('serviceWorker' in navigator) {
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }
    return this._httpClient.post<BrukerResponse>(
      'https://apiprivat.ishavskraft.no/bruker/koble/kode/' + kode,
      null
    );
  }

  getKunde(): Observable<KundeResponse> {
    return this._httpClient
      .get<KundeResponse>('https://apiprivat.ishavskraft.no/bruker/kunde')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.analytics.logEvent_errorFetch(
            'https://apiprivat.ishavskraft.no/bruker',
            `Status ${error.status} ${error.statusText} - ${error.url}`
          );
          return of(null);
        })
      );
  }

  getBrukerkunde(): Observable<BrukerkundeResponse> {
    return this._httpClient.get<BrukerkundeResponse>(
      'https://apiprivat.ishavskraft.no/bruker/brukerkunde'
    );
  }

  getSamtykke(): Observable<SamtykkeResponse> {
    return this._httpClient.get<SamtykkeResponse>(
      'https://apiprivat.ishavskraft.no/samtykke'
    );
  }

  sendSamtykke(
    whichSamtykke: number,
    newValue: boolean
  ): Observable<SamtykkeResponse> {
    let payload = '{ "kanal":"app", "';
    switch (whichSamtykke) {
      case 1:
        payload += 'nyheterIshavskraftSmsEpost';
        break;
      case 2:
        payload += 'nyheterPartnereSmsEpost';
        break;
      case 3:
        payload += 'tilbudTelefon';
        break;
      case 4:
        payload += 'tillaterSporing';
        break;
    }

    payload += '" : ' + newValue + '}';

    //  var headers={
    //    headers: new HttpHeaders({
    //        'Content-Type': 'application/json'
    //    })
    //}

    return this._httpClient.patch<SamtykkeResponse>(
      'https://apiprivat.ishavskraft.no/samtykke',
      payload
    );
  }

  // sendContact(payload: string): Observable<KundeResponse> {
  //   //var headers={
  //   //  headers: new HttpHeaders({
  //   //      'Content-Type': 'application/json'
  //   //  })
  //   //}

  //   return this._httpClient.put<KundeResponse>(
  //     'https://apiprivat.ishavskraft.no/bruker/update/kunde',
  //     payload
  //   );
  // }

  putBrukerkunde(kundeid: number): Observable<BrukerkundeResponse> {
    return this._httpClient.put<BrukerkundeResponse>(
      'https://apiprivat.ishavskraft.no/bruker/brukerkunde/' + kundeid,
      ''
    );
  }

  postBrukerkunde(epost: string): Observable<PostBrukerkundeResponse> {
    return this._httpClient.post<PostBrukerkundeResponse>(
      'https://apiprivat.ishavskraft.no/bruker/brukerkunde/' + epost,
      ''
    );
  }

  deleteBrukerkunde(kundeid: string): Observable<string> {
    return this._httpClient.delete<string>(
      'https://apiprivat.ishavskraft.no/bruker/brukerkunde/' + kundeid
    );
  }

  sendNewPassword(payload: string): Observable<PasswordResponse> {
    //var headers={
    //  headers: new HttpHeaders({
    //      'Content-Type': 'application/json'
    //  })
    //}

    return this._httpClient.post<PasswordResponse>(
      'https://apiprivat.ishavskraft.no/bruker/reset/password',
      payload
    );
  }

  getPoststed(postnr: string): Observable<string> {
    return this._httpClient.get(
      'https://apiprivat.ishavskraft.no/bruker/poststed/' + postnr,
      { responseType: 'text' }
    );
  }

  deleteKeycloakUser(userId: string) {
    return this._httpClient.delete(
      'https://apiprivat.ishavskraft.no/bruker/user/' + userId
    );
  }
}
