import { Routes } from '@angular/router';
import { brokerPage, loginArticleRoute, loginRoute, rootTabs } from './routes';
import { AuthGuard } from './services/guards/auth.guard';
import { logoutGuard } from './services/guards/logout.guard';
import { loginRouterStateGuard } from './services/guards/router-state.guard';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: loginRoute,
    pathMatch: 'full'
  },
  {
    path: loginRoute,
    title: 'Logg inn på Min Side – Ishavskraft',
    loadComponent: () =>
      import('./logginn/logginn.component').then((m) => m.LoggInnComponent)
  },
  {
    path: loginArticleRoute,
    loadComponent: () =>
      import('./tabs/nyttig/article/article.page').then((m) => m.ArticlePage),
    canActivate: [loginRouterStateGuard]
  },
  {
    path: rootTabs,
    title: 'Min Side – Ishavskraft',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule)
  },
  {
    path: brokerPage,
    loadComponent: () =>
      import('./broker/broker.page').then((m) => m.BrokerPage),
    canActivate: [logoutGuard]
  },
  {
    // 404 Not Found
    path: '**',
    redirectTo: loginRoute
  }
];
